<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{
            row.slug == "faqs"
              ? $t("app.question_price")
              : $t("app.consult_price")
          }}
        </h4>

        <div class="tab-danger pt-3">
          <div class="col-12 row">
            <div class="form-group col-4">
              <input
                type="number"
                class="form-control"
                v-model="row.price"
                v-on:input="onFormChange()"
              />
            </div>
          </div>

          <div><br /><br /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormPrices",
  props: ["price", "slug"],
  data() {
    return {
      row: {
        price: this.$props.price,
        slug: this.$props.slug,
      },
    };
  },
  mounted() {},
  methods: {
    onFormChange() {
      const data = {
        price: this.row.price,
      };
      this.$emit("pricesChange", data);
    },
  },
};
</script>
